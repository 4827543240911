  import React, { useState, useEffect } from "react";
  import { IoLogoWhatsapp } from "react-icons/io";
  import { Link, useLocation } from "react-router-dom";
  import PageHeader from "../ValidationPageComponent/PageHeader";
  import PageFooter from "../ValidationPageComponent/PageFooter";
  import {VerifyMobileAction} from '../../Redux-Store/actions/VerifyNumberAction'
  import * as Yup from "yup";
  import { useFormik, Form, FormikProvider , ErrorMessage} from 'formik';
  import ShowMessage from "../ShowMessage/ShowMessage";
  import { UseSelector,useDispatch } from "react-redux";
  import { RootState, AppDispatch } from '../../Redux-Store/store';
  import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";



  type Props = {
   
  };

  const MobileNumber = ( Props) => {

    const [value, setValue] = useState("");
    const [error, setError] = useState("");
    const [isShow, setIsShow] = useState(false)
    const [open,setOpen] =useState(false)
    const [navigateAfterMessage, setNavigateAfterMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [messageType, setMessageType] = useState<'success' | 'error'>('success')
    const [displayMessage, setMessage] = useState<{ text: string; type: 'success' | 'error' | null }>({ text: '', type: null });
  
    const navigate = useNavigate()
    const dispatch :AppDispatch =useDispatch()
    const { mobileVerification, isLoading, message ,is_error} = useSelector((state: RootState) => state.mobileVerification);
console.log("mobileVerification", mobileVerification)
    const {state} = useLocation();
    console.log("STATE",state);
    interface FormValues {
      numberField: string;
    }

    const resendTicketSchema= Yup.object({
      numberField: Yup.string()
      .matches(/^[0-9]+$/, 'Only numbers are allowed')
      // .max(10, 'Mobile number must be at most 10 digits')
      // .min(9, "please enter 10 digit mobile number")
      .length(10, 'Mobile number must be exactly 10 digits')  // Exactly 10 digits
      .required('WhatsApp mobile number is required'),
    })


    const backtoSelectionPage = () =>{
      navigate('/date-selection')
    }




    const formik= useFormik<FormValues>({
      initialValues: {
        numberField:""
      },
      validationSchema: resendTicketSchema,
      onSubmit:(value)=>{
        console.log(value)
        setMobileNumber(value.numberField)
        // setSubmitLoader(true);
        dispatch(
          VerifyMobileAction({
            'mobile_number': value.numberField
          })
        ).then((response:any)=>{
          console.log('response in loginkk', response);
          if (response.payload.meta.is_error===false) {
            setMessageType("success")
            setErrorMessage(response?.payload?.meta?.message);
            setMessage({ text: response?.payload?.meta?.message, type: 'success' });
            setIsShow(true);
            setNavigateAfterMessage(true);
            console.log('response', response?.data?.meta?.message);
          }else if (response?.payload.meta.is_error === true) {
            setMessageType('error')
            setErrorMessage(response?.payload?.meta?.message);
            setMessage({ text: response?.payload?.meta?.message, type: 'error' });
            setIsShow(true);
            setNavigateAfterMessage(false);
          } else {
            console.log('else');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      setTimeout(() => {setIsShow(false)}, 2000);
      }
    })


    useEffect(() => {
      if (navigateAfterMessage) {
        const timer = setTimeout(() => {
          navigate('/vehicleSelection', { state: { mobileNumber: mobileNumber , ...state } });
        }, 2000);
  
        return () => clearTimeout(timer);
      }
    }, [navigateAfterMessage, navigate, mobileNumber]);
  
  
    const { errors, touched, handleSubmit, getFieldProps } = formik;

    
    return (
      <>

        <div className="card-body">
          <div className="d-flex justify-content-center">
            <form action="" className="w-50" onSubmit={handleSubmit}>
              <div className="input-group input-group-lg flex-nowrap">
                <input
                 type="tel"
                  className="form-control"
                  maxLength={10}
                  placeholder="Enter WhatsApp Mobile Number"
                  aria-label="WhatsApp Mobile Number"
                  aria-describedby="addon-wrapping"
                  {...getFieldProps('numberField')}
                  pattern="[0-9]*"
                  
                />
                <span className="input-group-text bg-white" id="addon-wrapping">
                  <IoLogoWhatsapp className="textSuccessDark" />
                </span>
              </div>
              {touched.numberField && errors.numberField ? (
                        <div style={{ color: "red", marginLeft: 18 }}>
                          {errors.numberField}
                        </div>
                      ) : null}

              <div className="mt-4">
                <button
                  className="btnPrimary btn-lg w-100"
                  type="submit"
                >
            Validate Mobile Number
                </button>
                <button
                onClick={backtoSelectionPage}
                  className="btnSecondary btn-lg w-100 linkbtn-block mt-3"
                >
                  Back To Gate Selection
                </button>
              </div>
            </form>
          </div>
        </div>
      <ShowMessage
          onClose={() => setIsShow(false)}
          title={errorMessage}
          type={messageType}
          open={isShow}
        />
      </>
    );
  };

  export default MobileNumber;
