import React, { useEffect, useState } from "react";
import { FaBottleWater } from "react-icons/fa6";
import { Camera } from "react-bootstrap-icons";
import { AppDispatch, RootState } from "../../Redux-Store/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {confirmTicket} from '../../Redux-Store/actions/ConfirmTicketAction';
import water_bottle from '../../assets/water_bottle.png'
import camera from '../../assets/camera.png'

type Props = {};
interface Item {
  name: string;
  price: number;
  quantity: number;
}
const TicketCharges = ({ confirmTicketDataDetails, bookedSeats, gateId, planId }) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate()
  const [convFee, setConvFee] = useState(0);
  const [ipAddress, setIPAddress] = useState('')

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIPAddress(data.ip))
      .catch(error => console.log(error))
  }, []);

  const handleProceedPayment = () =>{
  const formattedBilling = confirmTicketDataDetails.components

  .map(item => ({
    component_id: item.component_id,
    qty: item.is_ticket ? bookedSeats : quantities[item.component_id] || 0,
    price: item.is_ticket ? (item?.component_price * bookedSeats) :
      (item.is_editable === 0 && item.is_ticket === 0 && item.is_ticket_based === 1 && item.price_type === "PERCENTAGE") ? convFee : (item?.component_price * quantities[item.component_id] || 0),
    item_rate: item?.component_price,
    sequence : item?.sequence
  }));

  
  dispatch(confirmTicket({
    booking_id:confirmTicketDataDetails.booking_id,
    booking_number:confirmTicketDataDetails.booking_number,
    gate_id : gateId,
    plan_id : planId,
    total_amount:grandTotal,
    booking_status:"payment_process",
    billing_components:formattedBilling,
    ip_address : ipAddress
  })).then((response:any)=>{
    console.log("response",response)
    if(response.payload.meta.is_error===false &&
       response.payload.meta.status===200){
      navigate('/processPayment',{
        state:{
          data: response.payload.data
        }
      })
    }
  })
}

  const [quantities, setQuantities] = useState(
    confirmTicketDataDetails.components.reduce((acc, item) => {
      acc[item.component_id] = item.min_qty;
      return acc;
    }, {})
  );

  useEffect(() => {
    confirmTicketDataDetails.components.find(item => {
      if(item.component_name === 'Ticket'){
        setQuantities((prev) => ({
          ...prev,
          [item.component_id] : bookedSeats
        }));
      }
    })
  }, [bookedSeats]);

  const handleDecrement = (id) => {
    setQuantities((prev) => ({
      ...prev,
      [id]: Math.max(prev[id] - 1, confirmTicketDataDetails.components.find(item => item.component_id === id).min_qty), // Ensure it doesn't go below min_qty
  
    }));
  };

  const handleIncrement = (id) => {
    setQuantities((prev) => {
      const maxQuantity = confirmTicketDataDetails.components.find(item => item.component_id === id)?.max_qty;
      const currentQuantity = prev[id] || 0;
      const newQuantity = Math.min(currentQuantity + 1, maxQuantity); 
  
      return {
        ...prev,
        [id]: newQuantity,
      };
    });
  };


  const ticketTotals = confirmTicketDataDetails.components
    .filter(item => item.is_editable === 0 && item.is_ticket === 1 && item.is_ticket_based === 0)
    .reduce((total, item) => total + (bookedSeats * item?.component_price), 0);

  const waterBottleCameraTotal = confirmTicketDataDetails.components
    .filter(item => item.is_editable === 1 && item.is_ticket === 0 && item?.is_ticket_based === 0)
    .reduce((total, item) => total + (quantities[item.component_id] * item?.component_price), 0);

  const subtotal = ticketTotals + waterBottleCameraTotal;

  // Now calculate the convenience fee
  const convenienceFeeComponent = confirmTicketDataDetails?.components?.find(item => item.is_editable === 0 && item.is_ticket === 0 && item.is_ticket_based === 1 && item.price_type === "PERCENTAGE");
  const convenienceFee = (subtotal * convenienceFeeComponent?.component_price) / 100;
  const grandTotal = subtotal + convenienceFee
  
  //set convenience fees
  useEffect(() => {
    setConvFee(convenienceFee);
  }, [convenienceFee]);
  

  const sortedComponents = confirmTicketDataDetails?.components.sort((a, b) => a.sequence - b.sequence);
  return (
    <>
      <div className="payment-summary">
        <div className="summary-items">
          {confirmTicketDataDetails?.components.map((item) => {
            if (item.is_editable === 1 && item.is_ticket === 0 && item.is_ticket_based === 0) {
              return (
                <div className="item-row" key={item?.component_id}>
                  <div className="item-info">
                    {item?.component_icon && (
                      <img
                        src={item.component_name==="Water Bottle"?  water_bottle:camera }
                        // alt={item.component_name}
                        style={{height: 40, width: 25}}
                        className="component-icon" // Optional: Add a class for styling
                      />
                    )}
                    <span>{item.component_name}</span>
                  </div>
                  <div className="item-controls">
                    <button
                      className="decrement me-2"
                      name={item.component_name}
                      onClick={() => handleDecrement(item.component_id)}
                      disabled={quantities[item.component_id] <= item.min_qty} // Disable if at min quantity
                    >
                      -
                    </button>
                    <span className="p-2 itemQty">{quantities[item.component_id]}</span>
                    <button
                      className="increment ms-2"
                      onClick={() => handleIncrement(item.component_id)}
                    >
                      +
                    </button>
                  </div>
                </div>
              );
            }
            return null;
          })}



        </div>


        {confirmTicketDataDetails?.components
          .filter(item => item.is_editable === 0 && item.is_ticket === 1 && item.is_ticket_based === 0)
          .map((item, index) => {
            console.log("items", item)
            return (
              <div className="passengers" key={item.component_id}>
                {/* Render the item details here */}
                <span>
                  {item.component_name}
                  <span className="ms-3">
                    <strong>{bookedSeats}</strong> X {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(item?.component_price)}
                  </span>
                </span>
                <span>
                  {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(bookedSeats * item?.component_price)}
                </span>

              </div>
            );
          })
        }


        {confirmTicketDataDetails.components
          .filter(item => item.is_editable === 1 && item.is_ticket === 0 && item.is_ticket_based === 0)
          .map((item, index) => {
            console.log("items", item);
            return (

              <div className="passengers" key={item?.component_id}>
                <span>
                  {item.component_name}
                  <span className="ms-3">
                    <strong>{quantities[item.component_id]}</strong> X {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(item?.component_price)}
                  </span>
                </span>
                <span>
                  {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(quantities[item.component_id] * item?.component_price)}
                </span>

              </div>
            );
          })
        }


        {confirmTicketDataDetails.components
          .filter(item => item.is_editable === 0 && item.is_ticket === 0 && item.is_ticket_based === 1 && item.price_type === "PERCENTAGE")
          .map((item, index) => {
            console.log("items", item);
            return (
              <div className="passengers" key={item.component_id}>
                <span>
                  {item.component_name}
                </span>
                <span>
                  {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(convenienceFee)}
                </span>

              </div>
            );
          })
        }

        <div className="grand-total">
          <span>Grand Total</span>
          <span>
            <strong> {grandTotal  ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(grandTotal) : "₹0"}</strong>
          </span>
        </div>

        <button className="proceed-button" onClick= {handleProceedPayment}>PROCEED TO PAYMENT</button>
      </div>
    </>
  );
};

export default TicketCharges;
